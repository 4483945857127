var isMobile = jQuery.browser.mobile;

$(document).ready(function () {
  fnAnimateLinks();
  AOS.init();
  var currentUrl = window.location.pathname;
  //fnSlideInit();
  fnHoverProducto();
  $(".list-productos li:nth-child(2) a").trigger("click");
  setTimeout(function(){
    $(".loader").fadeOut();
  },1200);

});

var fnAnimateLinks = function () {
  $("a").on('click', function (event) {
    if (this.hash !== "") {
      event.preventDefault();
      var hash = this.hash;
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function () {
        window.location.hash = hash;
      });
    }
  });
}

var fnSlideInit = function() {
  $("#slideshow > div:gt(0)").hide();

  setInterval(function() {
    $('#slideshow > div:first')
      .fadeOut(1000)
      .next()
      .fadeIn(1000)
      .end()
      .appendTo('#slideshow');
  }, 5000);
}


$('#colSlide').on('hidden.bs.collapse', function () {
  $(".toggle-slide img").attr("src", "assets/img/ico-toggle-slide-der.jpg");
});
$('#colSlide2').on('hidden.bs.collapse', function () {
  $(".toggle-slide img").attr("src", "assets/img/ico-toggle-slide-der.jpg");
});
$('#colSlide3').on('hidden.bs.collapse', function () {
  $(".toggle-slide img").attr("src", "assets/img/ico-toggle-slide-der.jpg");
});

$('#colSlide').on('shown.bs.collapse', function () {
  $(".toggle-slide img").attr("src", "assets/img/ico-toggle-slide.jpg");
});
$('#colSlide2').on('shown.bs.collapse', function () {
  $(".toggle-slide img").attr("src", "assets/img/ico-toggle-slide.jpg");
});
$('#colSlide3').on('shown.bs.collapse', function () {
  $(".toggle-slide img").attr("src", "assets/img/ico-toggle-slide.jpg");
});

var fnHoverProducto = function(){
  $(".img-detalle-producto").on("mouseenter", function(){
    $('.img-detalle-producto').each(function (index, value){
      var srcImgOff = $(this).attr("src").replace("-on.png", "-off.png");
      $(this).attr("src", srcImgOff);
    });
    var srcImg = $(this).attr("src");
    srcImg = srcImg.replace("off", "on");
    $(this).attr("src", srcImg);
  });
  $(".img-detalle-producto").on("mouseleave", function(){
    var srcImgProducto = $(".img-producto img").attr("src").replace("-img.png", "");
    var srcImg = $(this).attr("src").replace("-on.png", "");
    $('.img-detalle-producto').each(function (index, value){
      var srcImgOff = $(this).attr("src").replace("-on.png", "-off.png");
      $(this).attr("src", srcImgOff);
    });
    /* if(srcImgProducto == srcImg){
      $(this).attr("src", srcImg + "-on.png");
    }else{
      $(this).attr("src", srcImg + "-off.png");
    } */

    if($(this).parent("li").hasClass("selected")){
      $(this).attr("src", srcImg + "-on.png");
    }

  });
  $(".img-detalle-producto").on("click", function(e){
    e.preventDefault();
    $("#colSlide li").removeClass("selected");
    $(this).parent("li").addClass("selected");

    var dataMedidas = $(this).attr("data-medidas").split('-');
    var dataPropiedades = $(this).attr("data-propiedades").split('-');
    $(".medidas-producto .row .col:nth-child(1)").find("span:first-child").text(dataMedidas[0]);
    $(".medidas-producto .row .col:nth-child(2)").find("span:first-child").text(dataMedidas[1]);
    $(".medidas-producto .row .col:nth-child(3)").find("span:first-child").text(dataMedidas[2]);
    $(".propiedades-producto ul li:nth-child(1)").find("span:first-child").text(dataPropiedades[0]);
    $(".propiedades-producto ul li:nth-child(2)").find("span:first-child").text(dataPropiedades[1]);
    $(".propiedades-producto ul li:nth-child(3)").find("span:first-child").text(dataPropiedades[2]);
    $(".propiedades-producto ul li:nth-child(4)").find("span:first-child").text(dataPropiedades[3]);
    if(dataPropiedades[4] === " "){
      $(".propiedades-producto ul li:nth-child(5)").find("span").addClass("d-none");
    }else{
      $(".propiedades-producto ul li:nth-child(5)").find("span").removeClass("d-none");
      $(".propiedades-producto ul li:nth-child(5)").find("span:first-child").text(dataPropiedades[4]);
    }
    if(dataPropiedades[5] === " "){
      $(".propiedades-producto ul li:nth-child(6)").find("span").addClass("d-none");
    }else{
      $(".propiedades-producto ul li:nth-child(6)").find("span").removeClass("d-none");
      $(".propiedades-producto ul li:nth-child(6)").find("span:first-child").text(dataPropiedades[5]);
    }
    var srcImg = $(this).attr("src").replace("off", "on");
    $(this).attr("src", srcImg);
    srcImg = srcImg.replace("off", "img").replace("on", "img");

    $(".img-producto img").attr("src", srcImg);

  });
}

$(".list-productos li a").on("click", function(){
  var catSel = $(this).attr("data-categoria");
  $(".list-productos li").removeClass("active");
  $(this).parent("li").addClass("active");
  $(".vertical").addClass("d-none");
  $("."+catSel+"-slider").removeClass("d-none");
  $(".detalle-header-caracteristicas").addClass("d-none");
  $(".tit-"+catSel).removeClass("d-none");
  var imgSrc = $("."+catSel+"-slider li:first-child .img-detalle-producto:first-child").attr("src");
  imgSrc = imgSrc.replace("off", "on");
  $("."+catSel+"-slider li:first-child .img-detalle-producto:first-child").attr("src", imgSrc).trigger("click");

  $(".btn-pdf").addClass("d-none");
  $(".btn-pdf.btn-" + catSel).removeClass("d-none");

});


$(function() {
  $('#nosotros-01').click(function() {
    $('.nosotros .container-fluid:first-child').css('background-image', 'url(../assets/img/bg-nosotros.jpg)');
  });
  $('#nosotros-02').click(function() {
    $('.nosotros .container-fluid:first-child').css('background-image', 'url(../assets/img/bg-header-03.jpg)');
  });
  $('#nosotros-03').click(function() {
    $('.nosotros .container-fluid:first-child').css('background-image', 'url(../assets/img/bg-header-04.jpg)');
  });
});

$('#carouselHeader').carousel({
  pause: true,
  interval: 10000
});